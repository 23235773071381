import React, { useEffect } from 'react';
import { RequestContainer } from 'pages/Request/RequestContainer';
import { useSocketContext } from 'components/SocketContainer/SocketContext';
import { useMount } from 'hook/useMount';
import { getRequest, resetStore as resetRequestStore, useRequestData } from 'store/request/request';
import { useAppDispatch } from 'store/hooks';
import { ModalRate } from 'components/ModalRate';
import {
  MODAL_RATE_POPUP_OPENED_ACTIONS,
  REQUEST_UPLOADED_ACTION,
} from 'components/SocketContainer/consts';
import { QuizSocketData, setEntityId, setModalRateDate } from 'store/modalRate/modalRate';
import { modalRatePopupId } from 'components/ModalRate/consts';
import { useModals } from '@mozaic-ds/react';

export const RequestSocketContainer = ({ id }: { id: string }) => {
  const dispatch = useAppDispatch();

  const { open } = useModals();
  const { socket } = useSocketContext();
  const { request, selectedContainers } = useRequestData().data;

  useMount(() => {
    if (!request) dispatch(getRequest(id));

    return () => {
      dispatch(resetRequestStore());
    };
  });

  useEffect(() => {
    socket.on(REQUEST_UPLOADED_ACTION, () => {
      dispatch(getRequest(id));
    });
    socket.on(MODAL_RATE_POPUP_OPENED_ACTIONS, (data: QuizSocketData) => {
      dispatch(setEntityId(selectedContainers[0]?.id));
      dispatch(setModalRateDate(data));
      open(modalRatePopupId);
    });

    return () => {
      socket.off(REQUEST_UPLOADED_ACTION);
      socket.off(MODAL_RATE_POPUP_OPENED_ACTIONS);
    };
  }, [dispatch, id, open, selectedContainers, socket]);

  return (
    <>
      <RequestContainer id={id} />
      <ModalRate requestId={id} />
    </>
  );
};
