import React from 'react';
import { Notification, View } from '@mozaic-ds/react';
import { Stepper } from 'components/Stepper/Stepper';
import { useStepperContext } from 'components/Stepper/StepperContext';
import { Steps, StepsKey } from 'pages/CreatingRequest/types';
import { isSupplierEnvironment } from 'common/consts';
import { useAppTranslations } from 'translations';
import { getRequest, useRequestData } from 'store/request/request';
import { createMessageText } from 'pages/CreatingRequest/helpers';
import { useNavigate } from 'react-router-dom';
import styles from './CreatingRequest.module.scss';
import { stepsComponent } from './Steps/const';
import { SocketWrapper } from './SocketWrapper';
import { ModalRate } from '../../components/ModalRate';
import { RequestSocketContent } from './RequestSocketContent';
import { useAppDispatch } from '../../store/hooks';

export const CreatingRequest = () => {
  const dispatch = useAppDispatch();
  const translations = useAppTranslations();

  const navigate = useNavigate();
  const { request } = useRequestData().data;
  const { currentStepId } = useStepperContext<StepsKey>();

  const Component = stepsComponent[currentStepId];

  return (
    <View marginTop="mu250">
      {isSupplierEnvironment && <Stepper<keyof typeof Steps> />}
      {currentStepId !== Steps.start && request?.createDate && (
        <Notification
          theme="info"
          className="mu-mt-150"
          message={createMessageText({
            date: request.createDate,
            message: translations('requestShouldCreateAgainIfArticlesWasChangedNotification'),
            indexesOfInsertions: [0, 1],
            parseOptions: { separator: /[.,]/g },
          })}
        />
      )}
      <View
        className={styles.form}
        shadow="s"
        radius="l"
        padding="mu250"
        as="form"
        marginTop="mu150"
      >
        {currentStepId === Steps.start ? (
          <Component />
        ) : (
          <SocketWrapper>
            <RequestSocketContent>
              <Component />
              {currentStepId === Steps.finish && request?.id && (
                <ModalRate
                  actionAfterSuccessScoreRequest={() => {
                    navigate(`/request/${request.id}`);
                    dispatch(getRequest(request.id));
                  }}
                  requestId={request.id}
                />
              )}
            </RequestSocketContent>
          </SocketWrapper>
        )}
      </View>
    </View>
  );
};
