import { useController, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { sentFeedbackApi } from 'api/feedback/feedback';
import { EEntityName, Answer } from '@ppm/ppm-platform-feedback-service';
import { useAppDispatch } from 'store/hooks';
import { setThanYouPageState } from 'store/modalRate/modalRate';
import { FeedbackFormValue } from './types';

export const useFeedbackForm = (answers: Answer[]) => {
  const dispatch = useAppDispatch();

  const form = useForm<FeedbackFormValue>({
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues: {
      answerFields: answers.map((answer) => ({ ...answer, isCheckbox: false })),
      otherText: '',
    },
  });

  const { field: otherText } = useController({ name: 'otherText', control: form.control });

  const updateFormData = useCallback(
    async ({
      values,
      score,
      quizId,
      requestId,
      entityId,
    }: {
      values: FeedbackFormValue;
      score: number;
      quizId: number;
      entityId: string;
      requestId: string;
    }) => {
      await sentFeedbackApi({
        body: {
          answerIds: values.answerFields.filter((value) => value.isChecked).map(({ id }) => id),
          feedbackText: values.otherText || undefined,
          entityName: EEntityName.Container,
          rating: score,
          quizId,
          entityId,
          requestId,
        },
      });
      dispatch(setThanYouPageState(true));
    },
    [dispatch]
  );

  return {
    form,
    otherText,
    updateFormData,
  };
};
